import { css } from 'styled-components';
import mainTheme from './../data/theme.js';

export function whenTablet (input) {
  return css`
    @media only screen and (min-width: ${ mainTheme.breakpoints.tablet }) {
      ${ input }
    }
  `;
}

export function whenHalf (input) {
  return css`
    @media only screen and (min-width: ${ mainTheme.breakpoints.halfscreen }) {
      ${ input }
    }
  `;
}
