import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import theme from '../data/theme';

import logoImage from './../../static/assets/logo_small.png';

import { NestedNavMenu, InLineNavMenu } from './nav.js';
import { whenTablet, whenHalf } from '../utils/responsive';

import navSettings from '../data/nav';

const TopBand = styled.div`
  display: flex;
  justify-content: space-beteen;
  align-items: center;
  color: ${ theme.schemes.dark.color };

  max-width: 120rem;
  height: 16rem;
  margin: 0 auto;
  padding: 0 2rem;
`;

const LogoWrapper = styled.div`
  width: 60%;
  /* height: 100%; */

  ${ whenTablet(css`
    width: 20%;
  `) }
`;

const LogoLink = styled(Link)`
  display: block;
  width: 100%;
  height: 14rem;
  margin: 0;

  padding: 0rem 0;
  box-sizing: border-box;
  max-width: 30rem;
`;

const LogoImg = styled.img`
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  max-height: 100%;
`;

const MenuButtonWrapper = styled.div`
  width: 40%;
  text-align: right;

  ${ whenTablet(css`
    display: none;
  `) };
`;

const MenuButton = styled.button`
  border: 0;
  padding: 0.8rem 2rem;
  font-size: 1.8rem;
  font-weight: 600;
  background-color: ${ theme.schemes.special.backgroundColor };
  color: ${ theme.schemes.special.color };
`;

const QuoteButtonWrapper = styled.div`
  display: none;

  ${ whenHalf(css`
    display: block;
    text-align: right;
    width: 21%;
  `) }
`;

const QuoteButton = styled(Link)`
  text-decoration: none;
  border: 0;
  padding: 1.4rem 2.4rem;
  font-size: 2.0rem;
  font-weight: 600;
  background-color: ${ theme.schemes.special.backgroundColor };
  color: ${ theme.schemes.special.color };
`;

const NavMenuWrapper = styled.div`
  position: absolute;
  width: 100%;
  background-color: ${ theme.schemes.dark.backgroundColor };;
  color: ${ theme.schemes.dark.color };;
  z-index: 10;
  top: 16rem;
  box-shadow: 0px 5px 5px hsla(0, 100%, 0%, 0.4);

  ${ whenTablet(css`
    display: none;
  `) }
`;

const FullMenuWrapper = styled.div`
  display: none;
  margin-left: 5rem;
  font-weight: 600;

  ${ whenTablet(css`
    display: block;
    width: 80%;
  `) }

  ${ whenHalf(css`
    width: 59%;
  `) }
`;

const FullMenu = ({ primaryItems, headerState, toggleSubMenu, toggleSubMenusOff }) => {
  return <FullMenuWrapper>
    <InLineNavMenu
      primaryItems={primaryItems}
      subMenusOpen={headerState.wideSubMenusOpen}
      toggleSubMenu={toggleSubMenu}
      linkOnClick={toggleSubMenusOff}
      elsewhereOnClick={toggleSubMenusOff}
    />
  </FullMenuWrapper>;
};

const ExpandedMenu = ({ primaryItems, headerState, toggleMenu, toggleSubMenu }) => {
  return headerState.menuOpen
    ? <NavMenuWrapper>
      <NestedNavMenu
        primaryItems={primaryItems}
        subMenusOpen={headerState.subMenusOpen}
        linkOnClick={toggleMenu}
        toggleSubMenu={toggleSubMenu}
      />
    </NavMenuWrapper>
    : null;
};

class Header extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      menuOpen: false,
      subMenusOpen: Array(navSettings.headerNav.primaryItems.length).fill(false),
      wideSubMenusOpen: Array(navSettings.headerNav.primaryItems.length).fill(false),
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleSubMenu = this.toggleSubMenu.bind(this);
    this.toggleSubMenuExclusive = this.toggleSubMenuExclusive.bind(this);
    this.toggleSubMenusOff = this.toggleSubMenusOff.bind(this);
  };

  toggleMenu () {
    this.setState(prevState => ({ menuOpen: !prevState.menuOpen }));
  }

  toggleSubMenu (index) {
    this.setState(prevState => {
      return {
        subMenusOpen: prevState.subMenusOpen.map((isOpen, ix) => {
          return ix === index ? !isOpen : isOpen;
        })
      };
    });
  }

  toggleSubMenuExclusive (index) {
    this.setState(prevState => {
      return {
        wideSubMenusOpen: prevState.wideSubMenusOpen.map((isOpen, ix) => {
          if (ix === index) {
            return !isOpen;
          }
          return false;
        })
      };
    });
  }

  toggleSubMenusOff () {
    this.setState(prevState => {
      return {
        wideSubMenusOpen: prevState.wideSubMenusOpen.map((isOpen, ix) => {
          return false;
        })
      };
    });
  }

  render () {
    return <header css={`
      background-color: ${ theme.schemes.dark.backgroundColor };
    `}>
      <TopBand>
        <LogoWrapper>
          <LogoLink to='/'>
            <span css={`
              height: 100%;
              display: inline-block;
              vertical-align: middle;
            `}></span>
            <LogoImg src={logoImage} alt="VLP Landscaping Logo"></LogoImg>
          </LogoLink>
        </LogoWrapper>
        <MenuButtonWrapper>
          <MenuButton onClick={() => { this.toggleMenu(); }}>
            Menu
          </MenuButton>
        </MenuButtonWrapper>
        <FullMenu
          primaryItems={navSettings.headerNav.primaryItems}
          headerState={this.state}
          toggleSubMenu={this.toggleSubMenuExclusive}
          toggleSubMenusOff={this.toggleSubMenusOff}
        />
        <QuoteButtonWrapper>
          <QuoteButton as={Link} to='/quote/' onClick={() => { this.toggleMenu(); }}>
            Get a Quote
          </QuoteButton>
        </QuoteButtonWrapper>
      </TopBand>
      <ExpandedMenu
        primaryItems={navSettings.headerNav.primaryItems}
        headerState={this.state}
        toggleMenu={this.toggleMenu}
        toggleSubMenu={this.toggleSubMenu}
      />
    </header>;
  }
}

export default Header;
