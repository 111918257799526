import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { FaFacebookSquare, FaTwitter, FaInstagram, FaHome, FaLinkedin } from 'react-icons/fa';
import { MdPhoneInTalk, MdEmail } from 'react-icons/md';

import theme from '../data/theme';
import logoImage from './../images/vlp-logo.png';

const contactInfo = {
  phone: '440.420.3847',
  address: ['4661 Jaycox Rd', 'Avon, OH 44011'],
  email: 'mattvlp@yahoo.com',
  facebook: 'https://www.facebook.com/VLP-Landscaping-LLC-983403748512151',
  linkedin: 'https://www.linkedin.com/in/matthew-vojticek-369b3736',
  instagram: 'https://www.instagram.com/mattvlp/',
};

const ContactIconWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 4rem;
  margin-right: 2rem;
  line-height: 1;
`;

const ContactIconLink = styled.a`
  color: ${ props => props.color ? props.color : 'inherit' };
  &:hover {
    opacity: 0.6;
  }
`;

const ContactInfoWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 1.8rem;
`;

const ContactInfoLine = styled.p`
  line-height: 1.2;
  margin: 0;
  font-weight: ${ props => props.bold ? '600' : '400' };
`;

const ContactInfoLink = styled.a`
  line-height: 1.2;
  margin: 0;
  font-weight: ${ props => props.bold ? '600' : '400' };
  color: ${ props => props.color ? props.color : 'inherit' };
  text-decoration: none;

  &:hover {
    border-bottom: 2px solid;
  }
`;

const ContactWrapper = styled.div`
  ${ props => props.flex ? `
    margin: 2rem 3rem;
    min-width: 30rem;
    max-width: 30rem;
    flex: 1;
  ` : `
    margin: 3rem auto;
  ` };
}
`;

const Contact = props => (
  <ContactWrapper flex={props.flex}>
    <ContactIconWrapper>
      <ContactIconLink href={props.href} target="_blank" color={props.linkColor} rel="noopener">
        <props.icon/>
      </ContactIconLink>
    </ContactIconWrapper>
    <ContactInfoWrapper>
      {props.line1 && <ContactInfoLine bold>{props.line1}</ContactInfoLine>}
      {props.line2 && <ContactInfoLine>{props.line2}</ContactInfoLine>}
      {props.link && <ContactInfoLink href={props.href} color={props.linkColor} rel="noopener" target="_blank" bold>{props.link}</ContactInfoLink>}
    </ContactInfoWrapper>
  </ContactWrapper>
);

const ContactLinksWrapper = styled.div`
  margin: 3rem auto;
  text-align: left;
  max-width: ${ theme.maxContentWidth };
  ${ props => props.flex ? `
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  ` : null }
`;

const phoneHref = `tel:${ contactInfo.phone }`;
const addressHref = `https://www.google.com/maps/search/?api=1&query=${ encodeURIComponent(contactInfo.address.join(',')) }`;
const mailHref = `mailto:${ contactInfo.email }`;

export const ContactLinks = props => (
  <ContactLinksWrapper flex={props.flex}>
    <Contact icon={MdEmail} href={mailHref} line1="Email Us At" line2={contactInfo.email} flex={props.flex} linkColor={props.linkColor}/>
    <Contact icon={MdPhoneInTalk} href={phoneHref} line1="Call Us At" line2={contactInfo.phone} flex={props.flex} linkColor={props.linkColor}/>
    <Contact icon={FaHome} href={addressHref} line1={contactInfo.address[0]} line2={contactInfo.address[1]} flex={props.flex} linkColor={props.linkColor}/>
    <Contact icon={FaFacebookSquare} href={contactInfo.facebook} link="Visit Us On Facebook" flex={props.flex} linkColor={props.linkColor}/>
    <Contact icon={FaInstagram} href={contactInfo.instagram} link="Follow Us On Instagram" flex={props.flex} linkColor={props.linkColor}/>
    <Contact icon={FaLinkedin} href={contactInfo.linkedin} link="Find Us On LinkedIn" flex={props.flex} linkColor={props.linkColor}/>
  </ContactLinksWrapper>
);
