import React from 'react';
import styled, { css } from 'styled-components';
import { IoIosArrowDown } from 'react-icons/io';
import { Link } from 'gatsby';
import theme from './../data/theme';
import { whenTablet } from './../utils/responsive';

export const DownChevron = styled(IoIosArrowDown)`
  float: right;
  fill: ${ theme.schemes.dark.color };

  ${ whenTablet(css`
    display: inline-block;
    margin-left: 0.75rem;
  `) }
`;

export const PrimaryNavList = styled.ul`
  list-style-type: none;
  margin: 0;
  width: 100%;
  border-top: 1px solid ${ theme.schemes.dark.borderColor };

  ${ whenTablet(css`
    border: 0;
  `) }
`;

export const PrimaryItem = styled.li`
  margin: 0;

  ${ whenTablet(css`
    display: inline-block;
    position: relative;
  `) }
`;

export const PrimaryNavButton = styled.button`
  width: 100%;
  padding: 2rem 2rem;
  font-size: 1.8rem;
  font-weight: 600;
  background-color: inherit;
  border: 1px solid hsla(0, 0%, 0%, 0);
  border-bottom: 1px solid ${ theme.schemes.dark.borderColor };
  margin: 0;
  text-align: left;
  color: inherit;
  cursor: pointer;
  &:hover {
    border: 1px solid ${ theme.schemes.dark.underlineColor };
  }

  ${ whenTablet(css`
    padding: 1rem 2rem;
    font-size: 1.6rem;
    font-weight: 400;
    border: 0px;

    &:hover {
      border: 0;
    }
  `) }
`;

export const PrimaryDirectItem = styled.li`
  display: block;
  padding: 2rem 2rem;
  font-size: 1.8rem;
  font-weight: 400;
  border: 0;
  border-bottom: 1px solid ${ theme.schemes.dark.borderColor };
  margin: 0;
  text-align: left;
  text-decoration: none;
  color: inherit;

  ${ props => props.primary ? `
    color: ${ theme.schemes.special.color };
    background-color: ${ theme.schemes.special.backgroundColor };
    border-bottom: 1px solid ${ theme.schemes.special.borderColor };
  ` : null }

  ${ whenTablet(css`
    display: inline-block;
    padding: 3rem 2rem;
    font-size: 1.6rem;
    border: 0px;
  `) }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover {
    border-bottom: 2px solid;
  }
`;

export const PrimaryNavLink = styled(NavLink)`
  font-size: 1.8rem;
  font-weight: 600;
  border-color: ${ theme.schemes.dark.underlineColor };

  &:hover {
    border-bottom: 2px solid;
  }

  ${ props => props.primary && `
    border-color: ${ theme.schemes.special.borderColor };
  ` }

  ${ whenTablet(css`
    font-size: 1.6rem;
    font-weight: 400;
  `) }
`;

export const SecondaryNavList = styled.ul`
  border-bottom: 1px solid ${ theme.schemes.dark.borderColor };
  list-style-type: none;
  margin: 0;
  width: 100%;
  padding: 1.75rem 2.5rem;

  ${ whenTablet(css`
    position: absolute;
    background-color: ${ theme.schemes.light.backgroundColor };
    color: ${ theme.schemes.light.color };
    z-index: 10;
    left: 0;
    margin-top: 1px;
    min-width: 27rem;
    box-shadow: 0px 2px 13px 0px hsla(0, 100%, 0%, 0.3);
  `) }
`;

export const SecondaryItem = styled.li`
  margin: 0;
  display: block;
  width: 100%;
  font-weight: ${ props => props.primary ? '600' : '400' };
  padding: 0.75rem 0;
`;

export const SecondaryNavLink = styled(NavLink)`
  font-size: 1.8rem;
  font-weight: inherit;

  ${ whenTablet(css`
    font-size: 1.6rem;
  `) }
`;

export const SubMenu = ({ item, linkOnClick, colors }) => {
  return item.subItems
    ? <SecondaryNavList>
      {/* <SecondaryItem primary>
        <SecondaryNavLink to={item.url} onClick={linkOnClick}>{item.name}</SecondaryNavLink>
      </SecondaryItem> */}
      {
        item.subItems.map((subItem, ix) => (
          <SecondaryItem key={ix}>
            <SecondaryNavLink to={subItem.url} onClick={linkOnClick}>{subItem.name}</SecondaryNavLink>
          </SecondaryItem>
        ))
      }
    </SecondaryNavList>
    : null;
};

export const NestedNavMenu = ({ primaryItems, linkOnClick, subMenusOpen, toggleSubMenu }) => (
  <PrimaryNavList>
    {
      primaryItems.map((item, ix) => {
        return item.subItems && item.subItems.length > 0
          ? <React.Fragment key={ix}>
            <PrimaryItem onClick={() => toggleSubMenu(ix)}>
              <PrimaryNavButton><span>{item.name}</span><DownChevron /></PrimaryNavButton>
              {
                subMenusOpen[ix]
                  ? <SubMenu item={item} linkOnClick={linkOnClick}></SubMenu>
                  : null
              }
            </PrimaryItem>
          </React.Fragment>
          : <React.Fragment key={ix}>
            <PrimaryDirectItem primary={item.special}>
              <PrimaryNavLink to={item.url} onClick={linkOnClick} primary={item.special}>{item.name}</PrimaryNavLink>
            </PrimaryDirectItem>
          </React.Fragment>;
      })
    }
  </PrimaryNavList>
);

export class InLineNavMenu extends React.Component {
  constructor (props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  setWrapperRef (node) {
    this.wrapperRef = node;
  }

  handleClickOutside (event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.elsewhereOnClick();
    }
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render () {
    return <div ref={this.setWrapperRef}>
      <PrimaryNavList>
        {
          this.props.primaryItems.filter(item => !item.expandedOnly).map((item, ix) => {
            return item.subItems && item.subItems.length > 0
              ? <React.Fragment key={ix}>
                <PrimaryItem onClick={() => this.props.toggleSubMenu(ix)}>
                  <PrimaryNavButton><span>{item.name}</span><DownChevron /></PrimaryNavButton>
                  {
                    this.props.subMenusOpen[ix]
                      ? <SubMenu item={item}></SubMenu>
                      : null
                  }
                </PrimaryItem>
              </React.Fragment>
              : <React.Fragment key={ix}>
                <PrimaryDirectItem><PrimaryNavLink to={item.url} onClick={this.props.linkOnClick}>{item.name}</PrimaryNavLink></PrimaryDirectItem>
              </React.Fragment>;
          })
        }
      </PrimaryNavList>
    </div>;
  }
}
