export default {
  headerNav: {
    primaryItems: [
      {
        name: 'Our Services',
        url: '/services',
        subItems: [
          {
            name: 'Residential Landscaping',
            url: '/services/residential-landscaping/'
          },
          {
            name: 'Commercial Landscaping',
            url: '/services/commercial-landscaping/'
          },
          {
            name: 'Snow Removal',
            url: '/services/snow-removal/'
          },
          {
            name: 'Erosion Control',
            url: '/services/erosion-control/'
          },
          {
            name: 'Fertilization',
            url: '/services/fertilization/'
          }
        ]
      },
      {
        name: 'About Us',
        url: '/about/',
      },
      {
        name: 'Contact Us',
        url: '/about/#contact',
      },
      {
        name: 'Careers',
        url: '/careers/',
      },
      {
        name: 'Get A Free Quote',
        url: '/quote/',
        expandedOnly: true,
        special: true,
      }
    ]
  },
  'footerNav': {
    'primaryItems': [
      {
        'name': 'About Us',
        'url': '/about'
      },
      {
        'name': 'Services',
        'url': '/services/'
      },
      {
        'name': 'Contact Us',
        'url': '/about#contact'
      },
      {
        'name': 'Careers',
        'url': '/careers'
      }
    ]
  }
}