import React from 'react';
import styled from 'styled-components';

import Header from './Header';
import Footer from './Footer';

import theme from './../data/theme';


const Wrapper = styled.div`
  font-size: 10px;
  color: ${ theme.colors.black };
`

const Layout = props => {
  return <Wrapper>
    <Header></Header>
    {props.children}
    <Footer></Footer>
  </Wrapper>;
};

export default Layout;
