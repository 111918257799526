import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { ContactLinks } from './contact';

import { FaFacebookSquare, FaTwitter, FaInstagram, FaHome } from 'react-icons/fa';
import { MdPhoneInTalk, MdEmail } from 'react-icons/md';

import theme from '../data/theme';
import logoImage from './../../static/assets/logo_small.png';

const contactInfo = {
  phone: '440-420-3847',
  address: ['35929 Edgemere Way', 'Avon, OH 44011'],
  email: 'mattvlp@yahoo.com',
  facebook: 'https://www.facebook.com/VLP-Landscaping-LLC-983403748512151',
};

const FooterWrapper = styled.div`
  background-color: ${ theme.colors.darkPrimary };
  color: white;
  padding-top: 8rem;
  padding-bottom: 5rem;
  text-align: center;
`;

const LogoLink = styled(Link)`
  display: block;
  width: 100%;
  height: 100%;
  max-width: 28rem;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
`;

const FacebookIcon = styled(FaFacebookSquare)`
  /* fill: hsla(0, 0%, 0%, 0.75); */
`;

const TwitterIcon = styled(FaTwitter)`
  /* fill: hsla(0, 0%, 0%, 0.75); */
`;

const InstagramIcon = styled(FaInstagram)`
  /* fill: hsla(0, 0%, 0%, 0.75); */
`;

const SocialMediaLink = styled.a`
  text-decoration: none;
  color: inherit;
  font-size: 4rem;
  margin: 1.5rem;

  &:hover {
    fill: silver
  }
`;

const SocialMediaLinks = () => (
  <div css={`margin: 6rem 0;`}>
    <SocialMediaLink href={contactInfo.facebook}><FacebookIcon></FacebookIcon></SocialMediaLink>
    {/* <SocialMediaLink href="https://twitter.com/cbjvnmo/"><TwitterIcon></TwitterIcon></SocialMediaLink>
    <SocialMediaLink href="https://www.instagram.com/cbJvnmo/"><InstagramIcon></InstagramIcon></SocialMediaLink> */}
  </div>
);

const CopyStatement = styled.p`
  font-size: 1.8rem;
  margin: 5rem auto;
  text-align: center;
`;

const CopyRight = () => (
  <CopyStatement>
    &copy; 2019 VLP Landscaping, LLC
  </CopyStatement>
);

const QuoteButton = styled(Link)`
  display: inline-block;
  text-decoration: none;
  border: 0;
  padding: 1rem 2.2rem;
  font-size: 2.0rem;
  font-weight: 600;
  background-color: ${ theme.colors.white };
  color: ${ theme.colors.darkPrimary };
  margin: 5rem auto 3rem;
  &:hover {
    background-color: silver;
  }
`;

const Footer = props => (
  <FooterWrapper>
    <LogoLink to="/">
      <img src={logoImage} alt="VLP Landscaping Logo"></img>
    </LogoLink>
    <QuoteButton to="/quote/">Get A Free Quote</QuoteButton>
    <ContactLinks flex="true"/>
    {/* <SocialMediaLinks /> */}
    <CopyRight></CopyRight>
  </FooterWrapper>
)

export default Footer;