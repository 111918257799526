const colors = {
  black: 'hsla(80, 50%, 2%, 1)',
  primary: 'hsla(105, 75%, 25%, 1)',
  darkPrimary: 'hsla(105, 75%, 11%, 1)',
  cloudPrimary: 'hsla(105, 75%, 25%, 0.7)',
  cloud: 'hsla(0, 0%, 0%, 0.12)',
  lightcloud: 'hsla(0, 0%, 0%, 0.06)',
  white: 'white',
}

export default {
  colors,
  breakpoints: {
    tablet: '800px',
    halfscreen: '950px',
  },
  contentWidth: '93%',
  maxContentWidth: '120rem',
  maxTextWidth: '70rem',
  schemes: {
    dark: {
      backgroundColor: colors.darkPrimary,
      color: colors.white,
      underlineColor: colors.white,
      borderColor: 'gray',
    },
    light: {
      backgroundColor: colors.white,
      color: colors.black,
      underlineColor: colors.primary,
      borderColor: 'gray',
    },
    special: {
      backgroundColor: colors.white,
      color: colors.primary,
      underlineColor: colors.primary,
      borderColor: 'gray',
    },
  }
};
